import React, {
  useRef, useState,
} from 'react';
import imageUrlBuilder from '@sanity/image-url';
import { PortableText } from '@portabletext/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { buildMarksTree } from '@portabletext/toolkit';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyLoad from 'react-lazyload';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import { Typography } from '@tmap/mmm-style-guide/src/Typography';
import { Grid } from '@tmap/mmm-style-guide/src/Grid';
import { Box } from '@tmap/mmm-style-guide/src/Box';
import { Button } from '@tmap/mmm-style-guide/src/Button';
import { ArrowForward, ArrowForwardIos, ArrowRightAlt } from '@tmap/mmm-style-guide/src/Icon';
import { IconButton } from '@tmap/mmm-style-guide/src/IconButton';
import { ThemeProvider } from '@tmap/mmm-style-guide/src/Theming';
import { styled } from '@tmap/mmm-style-guide/src/styled';

import isEmptyish from '@tmap/mmm-core/isEmptyish';
import useView from '@tmap/mmm-core/redux/views/useView';

import Metatags from '../components/metatags';
import ColorBlocks from '../layouts/colorBlocks';
import CardAd from '../components/cardAd';
import PressReferences from '../components/pressReferences';
import FeaturedIncentivesBlock from '../components/homepage/featuredIncentivesBlock';
import FeaturedCommunitiesBlock from '../components/homepage/featuredCommunitiesBlock';
import AmenityBlock from '../components/homepage/amenityBlock';
import HiwBlock from '../components/homepage/hiwBlock';
import TestimonialsBlock from '../components/homepage/testimonialsBlock';
import ArticlesBlock from '../components/homepage/articlesBlock';
import ListCommunityBlock from '../components/homepage/listCommunityBlock';
import ResponsivePicture from '../components/responsivePicture';
import ConditionalLazyLoad from '../components/conditional-lazy-load';
import RecommendedContentBlock from '../components/homepage/recommendedContentBlock';
import ActiveOffersBlock from '../components/homepage/activeOffersBlock';

import getPrimaryIncentiveImage from '../lib/getPrimaryIncentiveImage';

import client from '../client';
import { DARK_THEME } from '../lib/themes';
import getPromotedIncentives from '../lib/incentive/getPromotedIncentives';
import getPromotedCommunities from '../lib/community/getPromotedCommunities';
import setCacheHeaders from '../lib/setCacheHeaders';
import { shuffle } from '../lib/helpers';
import useCurrentUser from '../hooks/useCurrentUser';
import { getCommunitiesOfMember } from '../actions/communityMembers';
import { SingleCommunityShortcut, MultiCommunityShortcut } from '../components/homepage/communityShortcutBlock';

const builder = imageUrlBuilder(client);

const infoComponents = {
  types: {
    block(props) {
      // TODO: Remove me and buildMarksTree import if they add children back to props again.
      if (!props.children) {
        // eslint-disable-next-line no-param-reassign
        props = {
          ...props,
          children: buildMarksTree(props.value).map((child, i) => props.renderNode({
            node: child,
            isInline: true,
            index: i,
            renderNode: props.renderNode,
          })),
        };
      }
      return props.children || null;
    },
  },
  marks: {
    strong(props) {
      return <Typography component='span' fontSize='inherit' color='textPrimary' fontWeight={600}>{props.children}</Typography>;
    },
  },
};

const HeroWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  height: '100%',
  width: '50%',
  alignItems: 'flex-start',
  textAlign: 'left',
  paddingBottom: theme.spacing(10),
  paddingTop: theme.spacing(6),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingRight: 0,
  },
}));

const HeroImage = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: theme.palette.grey[100],
  '& img, & .lazyload-wrapper': {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      minHeight: '100%',
    },
    maxHeight: '550px',
    objectFit: 'cover',
    borderRadius: 'inherit',
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(180deg, transparent 42%, rgba(31, 31, 35, 0.80) 80%)',
  },
}));

const HeroTestimonial = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  width: `calc(100% - ${theme.spacing(5)})`,
  height: '100%',
  maxWidth: '500px',
  paddingBottom: theme.spacing(10),
  paddingLeft: theme.spacing(4),
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const TestimonialPortrait = styled(Box, {
  shouldForwardProp: (p) => p !== 'colorIndex',
})(({ theme, colorIndex }) => {
  const colors = [
    theme.palette.green.main,
    theme.palette.aqua.main,
    theme.palette.pink.main,
  ];
  const portraitColor = colors[colorIndex % colors.length];
  return ({
    width: '64px',
    height: '64px',
    border: `3px solid ${portraitColor}`,
    background: portraitColor,
    borderRadius: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
      height: '100%',
      borderRadius: '64px',
    },
  });
});

const UnderlinedTypography = styled(Typography)(({ theme }) => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-1px',
    left: '0',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.primary.veryDark}`,
  },
}));

const SwiperWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  alignItems: 'flex-end',
  top: '0',
  left: '50%',
  height: '100%',
  width: '50%',
  marginLeft: theme.spacing(5),
  '& .swiper': {
    height: '100%',
  },
  [theme.breakpoints.down('md')]: {
    top: 'unset',
    left: 0,
    width: '100%',
    marginLeft: 0,
    height: '440px',
  },
}));

const SwiperSpacer = styled(Box)(({ theme }) => ({
  height: '440px',
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const SwiperNav = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  paddingLeft: theme.spacing(4),
  paddingBottom: theme.spacing(3),
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(3),
  },
}));

const SwiperNavBubble = styled(Box, {
  shouldForwardProp: (p) => p !== 'active',
})(({ theme, active }) => ({
  background: active ? theme.palette.primary.veryLight : theme.palette.grey[600],
  display: 'inline-block',
  transition: 'background 0.2s linear',
  width: '45px',
  height: '3px',
  borderRadius: '3px',
  marginLeft: '2px',
  marginRight: '2px',
}));

function Home(props) {
  const {
    doc,
    featuredIncentives = [],
    incentives = [],
    featuredCommunities = [],
    recentArticles = [],
    offerProgressSettings = {},
  } = props;

  const user = useCurrentUser();
  const [myCommunities] = useView(
    'header-my-communities',
    getCommunitiesOfMember(user),
  );

  const mixedIncentives = [...featuredIncentives.slice(1), ...incentives].slice(0, 8);
  const mainIncentive = featuredIncentives[0];

  const swiperRef = useRef();
  // swiper calls this function to obtain HTML for its pager.
  // so we provide it with none and perform our own effects.
  const [current, setCurrent] = useState();
  const renderCustomSwiperPager = (swiper, sCurrent) => {
    setCurrent(sCurrent);
    return '';
  };
  const handleNextTestimonial = () => {
    const here = swiperRef.current.swiper.realIndex;
    if (here === doc.heroTestimonials.length - 1) {
      swiperRef.current.swiper.slideTo(0);
    } else {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <>
      <Metatags
        title={doc.title || 'Home'}
        canonical={process.env.NEXT_PUBLIC_CANONICAL_DOMAIN}
        description={doc.metaDescription || ''}
        image={mainIncentive && builder.image(getPrimaryIncentiveImage(mainIncentive)).url()}
      />
      <ColorBlocks headerElevation={1}>
        <Box position='relative' mb={6} overflow='hidden'>
          <ColorBlocks.Block spacing='none' background={(theme) => theme.palette.primary.veryLight}>
            <HeroWrapper>
              <Typography component='h1' color='primary' variant='h3' fontWeight={900} fontSize='60px !important' mt={4} mb={3} sx={{ maxWidth: '400px', minWidth: { xs: 'unset', sm: '400px' } }}>
                {doc.leadCopy}
              </Typography>
              <Typography component='h2' variant='body1' color='textPrimary' mb={5.5} fontSize='18px' sx={{ maxWidth: '450px' }}>
                {doc.subLeadCopy}
              </Typography>
              <Grid container spacing={2} justifyContent='flex-start'>
                <Grid item xs={12}>
                  <Button href={doc.heroPrimaryLinkHref} size='large' variant='contained' color='secondary' endIcon={<ArrowForward />}>
                    {doc.heroPrimaryLinkTitle}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button href={doc.heroSecondaryLinkHref} size='large' variant='text' color='primary'>
                    <UnderlinedTypography color='primary.veryDark' fontSize='15px' fontWeight={500}>
                      {doc.heroSecondaryLinkTitle}
                    </UnderlinedTypography>
                  </Button>
                </Grid>
              </Grid>
            </HeroWrapper>
            <SwiperWrapper>
              <ThemeProvider theme={DARK_THEME}>
                <Swiper
                  ref={swiperRef}
                  slidesPerView={1}
                  modules={[Navigation, Pagination]}
                  pagination={{ type: 'custom', renderCustom: renderCustomSwiperPager }}
                >
                  {doc.heroTestimonials.map((testimonial, i) => (
                    <SwiperSlide key={testimonial._key}>
                      <HeroImage>
                        <ConditionalLazyLoad shouldLazyLoad={i > 0}>
                          <ResponsivePicture
                            image={testimonial.image}
                            width={710}
                            height={550}
                            xs={1.5}
                          />
                        </ConditionalLazyLoad>
                      </HeroImage>
                      <HeroTestimonial>
                        <Grid container spacing={1.5}>
                          <Grid item>
                            <TestimonialPortrait colorIndex={i}>
                              <ConditionalLazyLoad shouldLazyLoad={i > 0}>
                                <ResponsivePicture
                                  image={testimonial.profileImage}
                                  width={64}
                                  height={64}
                                  xs={2}
                                />
                              </ConditionalLazyLoad>
                            </TestimonialPortrait>
                          </Grid>
                          <Grid item>
                            <Typography color='textPrimary' fontSize='12px'>{testimonial.profileName}</Typography>
                            <Typography color='textPrimary' variant='overline' component='span'>
                              {testimonial.moveFrom}
                              <ArrowRightAlt sx={{ fontSize: '24px', mb: '-8px', mx: '3px' }} />
                              {testimonial.moveTo}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Typography color='textPrimary' fontSize='16px' fontWeight={700} mt={2} lineHeight='160%' letterSpacing='0.15px'>
                          {testimonial.leadCopy}
                        </Typography>
                      </HeroTestimonial>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <SwiperNav>
                  {doc.heroTestimonials.map((tm, i) => (
                    <SwiperNavBubble active={current === i + 1} key={tm._key} />
                  ))}
                  <IconButton onClick={handleNextTestimonial} size='small' sx={{ marginLeft: 2.5, border: (theme) => `1.5px solid ${theme.palette.grey[500]}`, padding: 0.25 }}>
                    <ArrowForwardIos sx={{ fontSize: '12px', color: (theme) => theme.palette.grey[500] }} />
                  </IconButton>
                </SwiperNav>
              </ThemeProvider>
            </SwiperWrapper>
            <SwiperSpacer />
          </ColorBlocks.Block>
        </Box>
        <ColorBlocks.Block spacing='small'>
          <Grid container spacing={5} justifyContent='center'>
            <Grid item sx={{ maxWidth: '650px' }}>
              <Typography variant='h4' fontWeight={800} fontSize='28px!important' textAlign={{ xs: 'left', md: 'center' }}>{doc.infoBlockLead}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justifyContent='space-around'>
                {doc.infoBlocks.map((block) => (
                  <Grid item xs={12} sm={6} md='auto' key={block._key}>
                    <Grid container spacing={{ xs: 2, md: 0.5 }} justifyContent={{ xs: 'flex-start', md: 'center' }}>
                      <Grid item xs='auto' md={12} sx={{ textAlign: { xs: 'left', md: 'center' } }}>
                        <picture>
                          <source srcSet={builder.image(block.image).width(48).height(48).format('webp')
                            .url()}
                          />
                          <img
                            src={builder.image(block.image).width(48).height(48).format('webp')
                              .url()}
                            alt={(block.image || {}).alt}
                            width={24}
                            height={24}
                          />
                        </picture>
                      </Grid>
                      <Grid item sx={{ maxWidth: { xs: '85%', md: '186px' } }}>
                        <Typography color='textSecondary' fontSize='14px' textAlign={{ xs: 'left', md: 'center' }}>
                          <PortableText
                            value={block.blockContent}
                            components={infoComponents}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </ColorBlocks.Block>
        {user?._id && (
          <LazyLoad>
            <ActiveOffersBlock offerProgressSettings={offerProgressSettings} />
          </LazyLoad>
        )}
        <ColorBlocks.Block color='light' overflow='lg'>
          {myCommunities?.length === 1 && (
            <SingleCommunityShortcut
              myCommunities={myCommunities}
              mainImage={doc.communityShortcutBlock.image}
              leadCopy={doc.communityShortcutBlock.leadCopy}
              subLeadCopy={doc.communityShortcutBlock.subLeadCopy}
            />
          )}
          {myCommunities?.length > 1 && (
            <MultiCommunityShortcut
              myCommunities={myCommunities}
              mainImage={doc.communityShortcutBlock.image}
              leadCopy={doc.communityShortcutBlock.leadCopy}
              subLeadCopy={doc.communityShortcutBlock.subLeadCopy}
            />
          )}
          {myCommunities?.length === 0 && (
            <>
              <ColorBlocks.Header buttonText='Explore Programs' href='/get-paid'>
                Trending Programs
                <ColorBlocks.SubHeader>
                  The perks and programming communities are offering for new movers.
                </ColorBlocks.SubHeader>
              </ColorBlocks.Header>
              <FeaturedIncentivesBlock featuredIncentives={mixedIncentives} />
            </>
          )}
        </ColorBlocks.Block>
        <ColorBlocks.Block>
          <ColorBlocks.Header buttonText='Learn More About Us' href='/how-it-works'>
            {doc.hiwBlockLead}
          </ColorBlocks.Header>
          <HiwBlock hiwBlocks={doc.hiwBlocks} />
        </ColorBlocks.Block>
        <ColorBlocks.Block color='dim' overflow>
          <ColorBlocks.Header buttonText='Explore Communities' href='/moving-living'>
            Popular Communities
            <ColorBlocks.SubHeader>
              The places and people who want to welcome you home.
            </ColorBlocks.SubHeader>
          </ColorBlocks.Header>
          <FeaturedCommunitiesBlock featuredCommunities={featuredCommunities} />
        </ColorBlocks.Block>
        <ColorBlocks.Block color='light' overflow>
          <ColorBlocks.Header buttonText='All Communities' href='/moving-living'>
            Perfectly curated communities by interest
            <ColorBlocks.SubHeader>
              Check out the communities we&apos;ve curated based on interests and lifestyle.
            </ColorBlocks.SubHeader>
          </ColorBlocks.Header>
          <AmenityBlock amenities={doc.amenityBlockCards} />
        </ColorBlocks.Block>
        <LazyLoad>
          <RecommendedContentBlock />
        </LazyLoad>
        {!user._id && (
          <ColorBlocks.Interstitial colorTop='light' colorBottom='light'>
            <CardAd
              leadCopy={doc.notificationBlockLead}
              subleadCopy={doc.notificationBlockSubLead}
            />
          </ColorBlocks.Interstitial>
        )}
        {!isEmptyish(doc.testimonials) && (
          <ColorBlocks.Block color='light' spacing='large'>
            <TestimonialsBlock testimonials={doc.testimonials} />
          </ColorBlocks.Block>
        )}
        <ColorBlocks.Block color='light' overflow='lg'>
          <PressReferences pressHits={doc.pressHits} lazy />
        </ColorBlocks.Block>
        <ColorBlocks.Block color='dim' overflow='lg'>
          <ColorBlocks.Header buttonText='Explore Articles' href='/articles'>Learn more about paid relocation</ColorBlocks.Header>
          <ArticlesBlock articles={recentArticles} />
        </ColorBlocks.Block>
        <ColorBlocks.Block color='light' spacing='large'>
          <ListCommunityBlock
            mainImage={doc.communityBlock.image}
            leadCopy={doc.communityBlock.leadCopy}
            subLeadCopy={doc.communityBlock.subLeadCopy}
          />
        </ColorBlocks.Block>
      </ColorBlocks>
    </>
  );
}

export const getServerSideProps = async (context) => {
  const [
    result,
    featuredIncentives,
    incentives,
    featuredCommunities,
    recentArticles,
    offerProgressSettings,
  ] = await Promise.all([
    client.fetch(`
      *[_id == "homeSettings"][0]{
        ...,
        pressHits[]{
          ...,
          image{..., asset->}
        },
        testimonials[]{
          ...,
          image{..., asset->},
          profileImage{..., asset->},
        }
    }
    `),
    getPromotedIncentives(9),
    client.fetch(`
      *[_type == "incentive" && status != "inactive"] {...,communities[]-> { regionText }} | order(totalValue desc)[0...5]
    `),
    getPromotedCommunities(5),
    client.fetch(`
      *[_type in ["articles","guides","research"]]{
      ...,
      "displayDate": coalesce(lastUpdated, _createdAt)
    } | order(displayDate desc)[0...3]
    `),
    client.fetch('*[_id == \'offerProgressSettings\'][0]'),
  ]);

  result.amenityBlockCards = shuffle(result.amenityBlockCards).slice(0, 6);

  setCacheHeaders(context.res);

  const analyticsProps = {
    pageName: 'home',
  };

  return {
    props:
    {
      analyticsProps,
      doc: result,
      featuredIncentives: featuredIncentives.filter((inc) => inc !== undefined),
      incentives,
      featuredCommunities: featuredCommunities.filter((inc) => inc !== undefined),
      recentArticles,
      offerProgressSettings,
    },
  };
};

export default Home;
