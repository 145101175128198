import Router from 'next/router';
import { fromKey } from '@tmap/mmm-core/entityKey';
import nestGet from '@tmap/mmm-core/nestGet';
import client from '../client';
import { getClient } from '../lib/apiClient';
import { addUserFavorite } from './user';
import {
  FETCHING_INCENTIVES,
  RECEIVE_INCENTIVES,
  SET_INCENTIVE_FILTER_RESULTS,
} from '../reducers/incentive';
import { jobTypes } from '../lib/filterTypes';

export function getIncentives(incentivesKeys, communityFields = ['regionText']) {
  return async (dispatch) => {
    const qs = incentivesKeys.map((i) => `"${fromKey(i).id}"`).join(',');
    const incentives = await client.fetch(`*[_type == "incentive" && _id in [${qs}]]{..., communities[]->{${communityFields.join(',')}}}`);
    dispatch({
      type: RECEIVE_INCENTIVES,
      incentives,
    });
  };
}

export function filterIncentives(params, limit = 18) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_INCENTIVES });
    let filterString = '';
    if (params.rz || params.st) {
      const geoFilters = [];
      if (params.rz) geoFilters.push(`regionZone in ["${params.rz.split(',').join('", "')}"]`);
      if (params.st) geoFilters.push(`locationGeo.state_abbreviation in ["${params.st.toUpperCase().split(',').join('", "')}"]`);
      filterString += ` && (${geoFilters.join(' || ')})`;
    }
    if (params.rt) {
      filterString += ` && regionType == "${params.rt}"`;
    }
    if (params.ot) {
      filterString += `&& (${params.ot.split(',').map((tag) => (
        `"${tag}" in values[].valueType`
      )).join(' || ')})`;
    }
    if (params.jt) {
      const jobFilters = params.jt.split(',');
      const professionIds = [];
      jobFilters.forEach((j) => {
        const jobType = jobTypes.find((jt) => jt.value === j);
        if (jobType?.taxonomyIds) {
          professionIds.push(...jobType.taxonomyIds);
        }
      });
      const professionsQuery = professionIds.map((p) => `'${p}' in professions`);
      if (jobFilters.includes('varied')) {
        professionsQuery.push(...['count(professions) == 0', 'professions == null']);
      }
      if (professionsQuery.length > 0) {
        filterString += ` && (${professionsQuery.join('||')})`;
      }
    }
    if (params.rm) {
      const remote = params.rm.split(',');
      const remoteQuery = remote.map(
        (p) => (p === 'inperson' ? 'inperson == true' : 'inperson != true'),
      ).join('||');
      filterString += ` && (${remoteQuery})`;
    }
    if (params.tag) {
      if (params.tag?.trim() === 'favorite') {
        const favorites = nestGet(getState(), ['users', 'favoriteListItems']);
        if (favorites && Object.values(favorites)?.length) {
          const favoriteIds = Object.values(favorites)
            .filter((f) => fromKey(f.itemKey).type === 'incentive' && f.status !== 'removed')
            .map((f) => `"${fromKey(f.itemKey).id}"`)
            .join(',');
          filterString += ` && _id in [${favoriteIds}]`;
        }
      } else {
        filterString += ` && "${params.tag}" in tags[].value`;
      }
    }
    const pagingRange = params.page ? `[${((params.page - 1) * limit)}...${(params.page * limit)}]` : `[0...${limit}]`;
    const query = `*[_type == "incentive" ${filterString}]{
      ...,
      communities[]->{regionText},
      "isFeatured": count(coalesce(tags, [])) > 0,
      "statusSortValue": select(
        status == null => 1,
        status == "active" => 1,
        status == "waitlist" => 2,
        status == "inactive" => 3
      )
    }`;
    const { incentives, total } = await client.fetch(`{
            "incentives": ${query} | order(statusSortValue asc, isFeatured desc, coalesce(totalValue, 0) desc) ${pagingRange},
            "total": count(${query})
        }`);
    dispatch({
      type: RECEIVE_INCENTIVES,
      incentives,
    });
    dispatch({
      type: SET_INCENTIVE_FILTER_RESULTS,
      results: incentives.map((i) => i._id),
      query: { page: 0, ...params },
      total,
    });
  };
}

export function requestAppUrl(id, options = {}) {
  const {
    redirect,
  } = options;
  return async (dispatch, getState) => {
    await getClient(process.env.NEXT_PUBLIC_API_DOMAIN, getState).post('/api/v1/offers/sendApplicationRequest', {
      sanityId: id,
    });

    if (redirect) {
      await Router.push(redirect);
    }
  };
}

export function saveAndRequestApp(id, url) {
  const entityKey = fromKey(id);
  return async (dispatch) => {
    dispatch(addUserFavorite(id));
    dispatch(requestAppUrl(entityKey.id, { redirect: url ? decodeURIComponent(url) : undefined }));
  };
}
