import { LeadStatus } from '@tmap/mmm-core/leads/status';

export const STATUS_LIST = [{
  status: LeadStatus.APPLIED,
  stepDescription: 'Applied',
  title: 'Like and Apply',
  description: 'Hit the \'heart\' to let the community know you\'re interested, or take the next step by applying for relocation incentives.',
}, {
  status: LeadStatus.PRE_APPROVED,
  stepDescription: 'Pre-Approved',
  title: 'Pre-Approved',
  description: 'Your application looks good! Verify your application details by submitting eligibility docs.',
}, {
  status: LeadStatus.ELIGIBILITY,
  stepDescription: 'Verified',
  title: 'Verified',
  description: 'You\'re waiting for a formal offer from the community. In the meantime, tell us about your housing search.',
}, {
  status: LeadStatus.OFFERED,
  stepDescription: 'Offered',
  title: 'Receive an Offer',
  description: 'The community has selected you to formally join the program! Review your offer details and sign on the dotted line.',
}, {
  status: LeadStatus.ACCEPTED,
  stepDescription: 'Accepted',
  title: 'Accept an Offer',
  description: 'You\'re in! Next up is to secure your housing. View your Housing Lead List or connect with an housing agent via Century 21, our relocation service partner.',
}, {
  status: LeadStatus.MOVED,
  stepDescription: 'Moved',
  title: 'Make Your Move',
  description: 'You\'ve secured housing in the community! Next up you can connect with PODS for our moving discount or connect with the program owner about receiving your program benefits.',
}];
